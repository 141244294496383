<template>
  <div class="pb grid_width">
    <div class="headerow">

      <h1>{{ currentCategoryName ? t(currentCategoryName) : '' }}</h1>

      <GeneralSearchShort
        @search="changeKeyword"
        :val="keyword"
        :placeholder="t('equipment_view_search_placeholder')"
      />

      <UiSort
        @choose="changeSort"
        :val="sort"  
        :options="[
          {id:'az_desc', name: $t('universal_sort_az'), icon: 'desc'},
          {id:'az_asc', name: $t('universal_sort_az'), icon: 'asc'},
          {id:'rate_desc', name: $t('universal_sort_rating'), icon: 'desc'},
          {id:'rate_asc', name: $t('universal_sort_rating'), icon: 'asc'},
          {id:'harvests_desc', name: $t('universal_sort_harvests'), icon: 'desc'},
          {id:'harvests_asc', name: $t('universal_sort_harvests'), icon: 'asc'},
          {id:'diaries_desc', name: $t('universal_sort_diaries'), icon: 'desc'},
          {id:'diaries_asc', name: $t('universal_sort_diaries'), icon: 'asc'},
        ]"
        />

      <GeneralSearchBoxes
        :tags="tags"
        :tagsselected="tagsSelected"
        :tagsdefault="TagsEquipments"
        :tagscount="sourceData?.items_tag"
        :tag-link="''"
        :modify-path="true"
        :is-show-counts="true"
        @choose="chooseTags"
        @unchoose="clearTags"
        @clear="clearTags"
      />
    </div>

    <br>


    <SkGrowerRows v-if="isLoading && start === 0" />
    <template v-else>
      <div v-if="sourceData?.items?.length" class="catalog_content min-height-window results two_column">
        <GeneralCatalogRow
          v-for="(item, index) in sourceData.items"
          :key="index"
          :rating="currentCategoryId == 'all' ? null : item.item_stat.rate"
          :logo="item.avatar_small"
          :link="item.link"
          :nm="item.name"
          :country="item.country"
          :item_stat="item.item_stat"
          :diaries="item.item_stat.cnt_reports"
          :growers="item.item_stat.cnt_growers"
          :harvests="item.item_stat.cnt_harvests"
          :products="item.item_stat.cnt_items"
          :rate="item.item_stat.avg_rate"
          :category="item.category"
          :chat-user="item.offical_user_id"
          :rateof="10"
          :types="item.spec_props"
        />

        <ClientOnly>
          <UiInfinityScroll
            :is-loading="isLoading"
            ref="elInfinityScroll"
          />
        </ClientOnly>
      </div>
      <UiEmpty v-else/>
    </template>
  </div>
</template>

<script setup lang="ts">

import { TagsEquipments, getTagsKeyword, getTagsKeys, getTagsSimple  } from '@/types/other'
import { useIntersectionObserver, watchArray } from '@vueuse/core'

const { t } = useI18n()
const { $api, $patcher, $ga, $constants } = useNuxtApp()
const isEnded = ref(false)
const route = useRoute()
const router = useRouter()
const start = ref(0)
const limit = ref(20)
const elInfinityScroll = ref(null)
const sort = ref('rate_asc');
const keyword = ref(''); 
const tags = ref(structuredClone(TagsEquipments))


const mapperCategory = [
  // {
  //   id: 'all',
  //   link: '/equipment',
  //   slug: 'category_equipment',
  //   tags: 'cg:tent,cg:grow_box,cg:ventilation_fan,cg:air_filter,cg:air_conditioner,cg:controller,cg:drip_system,cg:hydroponic_system,cg:co2_generator'
  // },
  {
    id: 'tent',
    link: '/equipment',
    slug: 'category_grow_tents',
    tags: 'cg:tent'
  },
  {
    id: 'tent',
    link: '/grow-tents',
    slug: 'category_grow_tents',
    tags: 'cg:tent'
  },
  {
    id: 'grow_box',
    link: '/grow-boxes',
    slug: 'category_grow_boxes',
    tags: 'cg:grow_box'
  },
  {
    id: 'ventilation_fan',
    link: '/ventilation-fans',
    slug: 'category_ventilation_fans',
    tags: 'cg:ventilation_fan'
  },
  {
    id: 'air_filter',
    link: '/air-filters',
    slug: 'category_air_filter',
    tags: 'cg:air_filter'
  },
  {
    id: 'air_conditioner',
    link: '/air-conditioners',
    slug: 'category_air_conditioners',
    tags: 'cg:air_conditioner'
  },
  {
    id: 'drip_system',
    link: '/drip-systems',
    slug: 'category_drip_systems',
    tags: 'cg:drip_system'
  },
  {
    id: 'hydroponic_system',
    link: '/hydroponic-systems',
    slug: 'category_hydroponic_systems',
    tags: 'cg:hydroponic_system'
  },
  {
    id: 'controller',
    link: '/controllers',
    slug: 'category_controllers',
    tags: 'cg:controller'
  },
  {
    id: 'co2_generator',
    link: '/co2-generators',
    slug: 'category_co2_generators',
    tags: 'cg:co2_generator'
  },
  {
    id: 'substrate',
    link: '/substrates',
    slug: 'category_substrates',
    tags: 'cg:substrate'
  }
]




const getCurrentCategory = (path: any) => {
  if(path) return mapperCategory.find(item => item.link === path || item.id === path)
  return mapperCategory.find(item => item.link === route.path)
}

const currentCategory = ref(getCurrentCategory(route.path))
const currentCategoryId = ref(currentCategory.value.id)
const currentCategoryName = ref(currentCategory.value.slug)
const currentCategoryPath = ref(currentCategory.value.path)
const tagsSelected = ref([currentCategoryId.value])
const tagsPermanent = ref(['cg:tent', 'cg:grow_box', 'cg:ventilation_fan', 'cg:air_filter', 'cg:air_conditioner', 'cg:controller', 'cg:drip_system', 'cg:hydroponic_system', 'cg:co2_generator', 'cg:substrate'])



const setCurrentCategory = (id) => {
  currentCategory.value = getCurrentCategory(id)
  currentCategoryId.value = currentCategory.value.id
  currentCategoryName.value = currentCategory.value.slug
  currentCategoryPath.value = currentCategory.value.link?.replace('/', '')
  tagsSelected.value = [currentCategory.value.id]
}



const clearTags = () => {
  console.log('unchoose');
  // router.push(mapperCategory[0].link)
  tagsSelected.value = ['tent'];
  start.value = 0;
  

  setCurrentCategory('tent');

  $patcher.change('', {
    tags: [currentCategoryPath.value],
    sort: sort.value,
  });
}



const chooseTags = (selectedId: any) => {


  console.log('selectedId', selectedId);

  tagsSelected.value = [selectedId]
  start.value = 0;

  
  setCurrentCategory(selectedId);

  $patcher.change('', {
    tags: [currentCategoryPath.value],
    sort: sort.value,
  });

  // start.value = 0;

}

const loadData = async () => {
  return await $api.getBrands({
    // ...filterParams,
    sortable: sort.value,
    keyword: keyword.value,
    start: start.value,
    limit: limit.value,
    // tagsall: "all",
    // tags: tagsSelected.value.join(',')

    tagsall: getTagsKeys(tags.value), 
    tags: getTagsSimple(tags.value, tagsSelected.value),
    tagsper: getTagsSimple(tags.value, tagsPermanent.value)
  })
}
 
const changeSort = (val: string) => {
  start.value = 0
  sort.value = val
  // getBrandData()
}
const changeKeyword = (val: string) => {
  start.value = 0
  keyword.value = val
  // getBrandData()
}

const { pending: isLoading,  data: sourceData } = await useLazyAsyncData('sourceData', async () => await loadData())





watchArray([start, sort, tagsSelected, keyword], async ([new_start, new_sort, new_tagsSelected, new_keyword], added, removed) => {
  console.log('watcher');

  if(new_start == 0){
    sourceData.value.items = [];
    isEnded.value = false;
  } 
 
  
  isLoading.value = true;
  var dt = await loadData();

  if(dt.length == 0)
    isEnded.value = true;  

  sourceData.value.items = [...sourceData.value.items, ...dt.items]
  if(dt.items_tag?.length)
    sourceData.value.items_tag = dt.items_tag;

  isLoading.value = false;

  

}, {deep: true})



useIntersectionObserver(elInfinityScroll, ([{ isIntersecting }]) => {
  if(isIntersecting && !isLoading.value && !isEnded.value){
    start.value = start.value + limit.value;
  }
}, { distance: 10 })

watchArray([route], async ([new_route], added, removed) => {
  useHead(useNuxtApp().$head.getBrand(sourceData.value))
}, {deep: true})

useHead(useNuxtApp().$head.getBrand(sourceData.value))

</script>


<style scoped>

h1 {
  margin-right: auto;
}

.headerow {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
 

@container pb (max-width: 700px) {
  .headerow > .search {
    order: 1;
    width: 100%;
  }

  .headerow > .stags {
    margin: 0;
    order: 0;
  }

  .headerow > .sortable {
    justify-content: flex-end;
    margin: 0;
    order: 2;
    width: 100%;
  }
}


</style>